import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Marginer } from "../../components/marginer";
import { OurSerivce } from "../../components/ourService";
import { SectionTitle } from "../../components/sectionTitle";

import CSS2 from "../../assets/illustrations/CSS2.png";
import image1 from "../../assets/illustrations/Redeem.png";
import image2 from "../../assets/illustrations/svg-13.svg";
import Service3Img from "../../assets/illustrations/bug_fixed.png";

const ServicesContainer = styled(Element)`
  width: 100%;
  min-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

export function ServicesSection(props) {
  return (
    <ServicesContainer name="servicesSection">
      <SectionTitle>About Us</SectionTitle>
      <Marginer direction="vertical" margin="3em" />
      <OurSerivce
        title="Crypto Sport Stars"
        description="Crypto Sport Stars is Centaur Labs first creation. Innovative smart contract business model to allow a community to support local and global athletes. CSS is disrupting the sports industry and creating a new standard globally. We have made it so that athlete will receive royalties on every transaction. "
        imgUrl={CSS2}
      />
      <OurSerivce
        title="Crypto Sports League in 2024"
        description="We will launch a sports league in 2024! More info coming soon! Stay tuned!"
        imgUrl={image1}
        isReversed
      />
      <OurSerivce
        title="We are mixing NFTs with DeFi!"
        description="We are current developing an advance wallet protocol that allows for royalties and a community incentive engagement program. We are currently innovating around established standards such as ERC20, ERC721, and ERC1155. Stay tuned!"
        imgUrl={image2}
      />
    </ServicesContainer>
  );
}
